import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get } from 'lodash';
import {
  ChatThread,
  CustomerChatInput,
  ChatEndConfirmation,
  ScrollContainer,
} from '@nextiva/chat-widgets';

const ChatContainer = ({
  jid,
  messages,
  inputValue,
  isChatEnded,
  onInputChange,
  onSendForm,
  onSendMessage,
  showEndChatConfirmation,
  onChatEndConfirm,
  onChatEndCancel,
}) => {
  const blockName = 'nx-chat-customer-container';

  const renderIntro = useCallback(
    () => (
      <div className={`${blockName}__messages-placeholder`}>
        Ask a question or make a request and we&apos;ll chat with you as soon as
        we can.
      </div>
    ),
    [],
  );

  const renderContent = useCallback(
    () => (
      <ChatThread
        className={`${blockName}__chat-thread`}
        data={messages}
        jid={jid}
        isCustomer
        onSendMessage={onSendForm}
      />
    ),
    [jid, messages, onSendForm],
  );

  const showIntro = messages.length === 0;
  const firstMessage = !showIntro && messages[0];
  const messageType = get(firstMessage, ['type']);
  // TODO: change the logic of showing input once Chat Builder is ready
  const showInput =
    !isChatEnded &&
    !showEndChatConfirmation &&
    (firstMessage ? messageType === 'form_result' : false);

  const containerClassName = cx(`${blockName}__messages-wrapper`, {
    [`${blockName}__messages-wrapper--with-confirmation`]: showEndChatConfirmation,
    [`${blockName}__messages-wrapper--with-placeholder`]: showIntro,
  });

  return (
    <div className={blockName}>
      <React.Fragment>
        <ScrollContainer className={containerClassName} shouldScrollToBottom>
          {showIntro ? renderIntro() : renderContent()}
        </ScrollContainer>
      </React.Fragment>
      {showEndChatConfirmation && (
        <React.Fragment>
          <ChatEndConfirmation
            onConfirm={onChatEndConfirm}
            onCancel={onChatEndCancel}
          />
        </React.Fragment>
      )}
      {showInput && (
        <div className={`${blockName}__input-wrapper`}>
          <CustomerChatInput
            inputClassName={`${blockName}__input`}
            onChange={onInputChange}
            onSendMessage={onSendMessage}
            value={inputValue}
            maxHeight={100}
          />
        </div>
      )}
    </div>
  );
};

ChatContainer.propTypes = {
  jid: PropTypes.string.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onSendForm: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  isChatEnded: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  showEndChatConfirmation: PropTypes.bool,
  onChatEndConfirm: PropTypes.func,
  onChatEndCancel: PropTypes.func,
};

ChatContainer.defaultProps = {
  messages: [],
  showEndChatConfirmation: false,
};

export default ChatContainer;
