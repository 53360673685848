import { get, split } from 'lodash';
import uuid from 'uuid-random';
import { getDomain } from '@nextiva/chat-core';
import getSnippetElement from './getSnippetElement';

const localDevelopmentConfig =
  'MjEwMTkyNmUtYjA4My0xMWU4LWI0MGItMDA1MDU2OGUwNGQzfn5kZXZlbG9wbWVudA==';

const script = getSnippetElement();
const scriptOptions = JSON.parse(get(script, 'innerText', '{}'));
const config = atob(
  get(scriptOptions, 'nextivaChatConfig', localDevelopmentConfig),
);

const getUsername = () => {
  const {
    location: { hostname },
  } = window;
  const userID = uuid();
  const [queue] = split(config, '~~');
  return `customer~~${userID}~~${hostname}~~${queue}`;
};

const getToken = () => uuid();

export default () => ({
  username: getUsername(),
  domain: getDomain(),
  token: getToken(),
});
