import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import root from 'react-shadow';
import { get, map } from 'lodash';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import {
  Constants,
  updateAppStatus,
  Event,
  Message,
  Talks,
  selectActiveUserStatus,
  selectActiveUserID,
  selectChatsJID,
  selectChatMeta,
  selectCustomerMessages,
  selectUsers,
  selectProactivityConfig,
} from '@nextiva/chat-core';
import { ChatButton, CustomerChatWindow } from '@nextiva/chat-widgets';
import ChatContainer from './ChatContainer';
import styles from './styles.scss';
import wrapperStyles from './App.scss';

let hasBeenOpened = false;

const App = ({
  activeUser,
  status,
  sendMessage,
  sendForm,
  leaveChat,
  messages,
  to,
  users,
  proactivity,
  setWindowOpenedOnProactivity,
  chatMeta,
}) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [conversationEnding, setConversationEnding] = useState(false);
  const [inputValue, setInputValue] = useState();
  const isProactivityEnabled = get(proactivity, 'enabled');
  const online = status
    ? status.value === Constants.UserStatuses.Online
    : false;
  const proactivityTimeout = useRef(null);

  const dropProactivityTimeout = () => {
    if (proactivityTimeout.current) {
      clearTimeout(proactivityTimeout.current);
      proactivityTimeout.current = undefined;
    }
  };

  const handleSendMessage = message => {
    setInputValue('');
    sendMessage(to, message);
  };

  const openChat = (isProactive = false) => {
    if (!isChatOpen) {
      hasBeenOpened = true;

      dropProactivityTimeout();

      setWindowOpenedOnProactivity(isProactive);
      setIsChatOpen(true);
    }
  };

  const handleCloseClick = () =>
    to ? setConversationEnding(true) : setIsChatOpen(false);

  const handleCloseConfirm = () => {
    setIsChatOpen(false);
    setConversationEnding(false);
    leaveChat(to);
  };

  const handleCloseCancel = () => setConversationEnding(false);

  const serializeMessages = () =>
    map(
      messages,
      ({
        payload: { content, type } = {},
        meta: { timestamp, from = activeUser, sender: { id, name } = {} },
      }) => ({
        timestamp,
        content,
        type,
        from: id,
        name: name || get(users, [from, 'name'], from),
      }),
    );

  useEffect(() => {
    if (isProactivityEnabled && !hasBeenOpened) {
      const timeout = get(proactivity, 'timeout');

      if (!proactivityTimeout.current) {
        proactivityTimeout.current = setTimeout(() => {
          openChat(true);
          dropProactivityTimeout();
        }, timeout);
      }
    }

    return () => {
      dropProactivityTimeout();
    };
  });

  const finalClassName = cx('nx-chat-customer', {
    [`nx-chat-customer--online`]: online,
  });
  const isChatEnded = get(chatMeta, 'chat_ended', false);

  return (
    <div className={finalClassName}>
      {online && (
        <root.div>
          {isChatOpen ? (
            <CustomerChatWindow
              closeHandler={handleCloseClick}
              online
              isCustomer
            >
              <ChatContainer
                messages={serializeMessages()}
                jid={activeUser}
                isChatEnded={isChatEnded}
                onSendMessage={handleSendMessage}
                onSendForm={sendForm}
                inputValue={inputValue}
                onInputChange={setInputValue}
                showEndChatConfirmation={conversationEnding}
                onChatEndConfirm={handleCloseConfirm}
                onChatEndCancel={handleCloseCancel}
              />
            </CustomerChatWindow>
          ) : (
            <ChatButton onClick={() => openChat()} />
          )}
          <style type="text/css">{styles}</style>
        </root.div>
      )}
      <style type="text/css">{wrapperStyles}</style>
    </div>
  );
};

App.propTypes = {
  connect: PropTypes.func,
  sendPresence: PropTypes.func,
  sendMessage: PropTypes.func,
  sendForm: PropTypes.func,
  status: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  activeUser: PropTypes.string,
  to: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  leaveChat: PropTypes.func.isRequired,
  proactivity: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    timeout: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  setWindowOpenedOnProactivity: PropTypes.func.isRequired,
  chatMeta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    released: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state /* , ownProps */) => {
  const [to] = selectChatsJID(state);

  return {
    to,
    status: selectActiveUserStatus(state),
    messages: selectCustomerMessages(to)(state),
    activeUser: selectActiveUserID(state),
    users: selectUsers(state),
    proactivity: selectProactivityConfig(state),
    chatMeta: selectChatMeta(to)(state),
  };
};

const mapDispatchToProps = (dispatch /* , ownProps */) => ({
  sendMessage: (to, message) => dispatch(Message.Send({ to, message })),
  sendForm: data =>
    dispatch(
      Event.Output.Routing.NextStep({
        type: Constants.Routing.Types.formResponse,
        data,
      }),
    ),
  leaveChat: to => dispatch(Talks.Leave({ jid: to })),
  setWindowOpenedOnProactivity: isProactive =>
    dispatch(
      updateAppStatus({
        parameter: Constants.AppStatuses.WindowOpenedOnProactivity,
        value: isProactive,
      }),
    ),
});

export default hot(module)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
