import getSnippetElement from './getSnippetElement';

let tenantHost = null;

/**
 * getTenantHost
 * In order to figure out the address of tenant's xmpp endpoint we take the script's src
 * and use it as the host since nextiva.nextos.com is not accessible outside of
 * Nextiva networks.
 */
const getTenantHost = () => {
  if (tenantHost !== null) return tenantHost;

  const script = getSnippetElement();
  if (!script) return window.location.host;

  const { src } = script;
  const a = document.createElement('a');
  a.href = src;
  tenantHost = a.hostname;

  return tenantHost;
};

export default getTenantHost;
