import { cloneDeep } from 'lodash';
import reduceReducers from 'reduce-reducers';
import { combineEpics } from 'redux-observable';

import {
  configureStore,
  epic as coreEpic,
  reducer as coreReducer,
  updateConnectionProps,
  isCustomer,
  Connection,
} from '@nextiva/chat-core';

import { credentialsGenerator, getTenantHost } from '../utils';

import initialState from './constants';

const reducers = reduceReducers(coreReducer);

const epics = combineEpics(coreEpic);

const store = configureStore(reducers, epics, cloneDeep(initialState));

store.dispatch(isCustomer());
store.dispatch(
  updateConnectionProps({
    protocol:
      process.env.REACT_XMPP_PROTOCOL ||
      (/https/g.test(window.location.protocol) ? 'wss' : 'ws'),
    host: getTenantHost(),
    endpoint: process.env.REACT_XMPP_ENDPOINT || '/apps/next-chat/xmpp',
  }),
);
store.dispatch(Connection.Init());
store.dispatch(Connection.Connect(credentialsGenerator()));

export default store;
