import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import store from './store';

const chatElemId = 'next-chat-button';
const chatAlreadyExists = document.querySelector(`#${chatElemId}`);

if (chatAlreadyExists === null) {
  const chatElem = document.createElement('div');
  chatElem.id = chatElemId;

  document.body.appendChild(chatElem);

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById(chatElemId),
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
} else {
  console.error(
    `
    Nextiva Chat code snippet was added more than once on the following page: ${document.location.href}
    Only the code snippet that loaded first was initialized. Please remove any extra code snippets from the page.
    `,
  );
}
