/**
 * getSnippetElement
 * finds code snippet script on customer's page and returns it
 */
const getSnippetElement = () => {
  const [script] = document.getElementsByName('next-chat');
  return script;
};

export default getSnippetElement;
